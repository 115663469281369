import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import Grid from "@material-ui/core/Grid"
import PrimaryButton from "./../components/buttons/primaryButton"
import styled from "styled-components"
import { theme } from "./../components/layout/theme"

const ErrorPage = styled.div`
  min-height: calc(100vh - 500px);
`

const H1404 = styled.h1`
  font-size: 6rem;
  color: ${theme.palette.primary.main};
  text-align: center;
  margin-top: 90px;
`
const H2404 = styled.h2`
  text-align: center;
  margin: 60px auto;
`

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Layout>
      <ErrorPage>
        <Grid container justify="center">
          <Grid item lg={8} md={8} xs={11}>
            <H1404>404</H1404>
            <H2404>Lo sentimos, la página que busca no existe</H2404>
            <PrimaryButton href="/" style={{ color: "white" }}>
              Regresar a inicio
            </PrimaryButton>
          </Grid>
        </Grid>
      </ErrorPage>
    </Layout>
  </>
)

export default NotFoundPage
